import React from "react";
import {Helmet} from "react-helmet";
import {compagnyName, compagnyUrl} from "../config/settings";


const tableStyle = {
    borderSpacing: "0",
    width: '688px',
    borderCollapse: 'collapse'
};

const SignatureStyle = {
    //borderSpacing: "20px",
    borderCollapse: 'collapse'
};

const contentBlock = {
    backgroundColor: '#000000',
    color: '#fff'
}
const advertBlock = {
    width: '100%',
    height: '83px',
    backgroundColor: '#e8be62'
}

const ImageWrapper = {
    width: "330px",
};
const Image = {
    width: "284px",
    height: "234px",
    //margin: "auto",
    //paddingLeft: "60px",
    display: "block",
};

const ImagePadding = {
    width: "60px",
}
const TextWrapper = {
    marginLeft: "20px",
    fontFamily: "Arial",
    lineHeight: "1.45",
    fontSize: "19px"
};
const Name = {
    margin: "0",
    fontFamily: "Arial",
    fontSize: "19px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.47
};
const Job = {
    margin: "12px 0 30px 0",
    fontSize: "17px",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.18,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#e8be62",
    width: '90%',
    whiteSpace: "pre-wrap"
};

const Phone = {
    fontWeight: "bold",
    fontSize: "14px",
    color: "#fff",
    margin: "0px 0 25px 0",
};
const PhoneLink = {
    textDecoration: "none",
    color: "#fff",
};


const Socials = {
    marginBottom: '35px'
}

const Social = {
    //marginRight: '15px'
}
const SocialImg = {
    width: '35px',
    height: '35px',
}

const advertTextWrapper = {
    paddingRight: '60px',
    paddingLeft: '60px',
    fontFamily: "Arial",
    fontSize: "19px",
    fontWeight: "normal",
    fontStretch: "normal",
    lineHeight: "1.26",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#000"
}

const Web = {
    padding: '4px 7px',
    border: "solid 1px #fff",
    textDecoration: 'none',
    fontFamily: "Arial",
    fontSize: "15px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.13,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#fff",
    //marginBottom: '25px',
    //display: 'table-caption'
}

const SeparatorX = {
    borderLeft: "solid 1.5px #333333",
    width: '40px'
}
const SeparatorY = {
    display: 'block',
    width: '568px',
    borderTop: "solid 1.5px #333333",
    paddingBottom: '15px',
    marginLeft: '60px'
}

const AddressesBlock = {
    backgroundColor: 'black',
    color: '#fff'
}

const Address = {
    fontFamily: "Arial", fontSize: "11px", lineHeight: 1.27,
    color: '#fff', textDecoration: 'none'
}

const AddressItem = {
    width: '200px'
}

const AddressTitle = {
    textTransform: 'uppercase',
    fontFamily: "Arial",
    fontSize: "12px",
    fontWeight: "bold",
    lineHeight: 1.33,
    color: "#e8be62"
}

const AddressPhone = {
    fontFamily: "Arial",
    fontSize: "12px", fontWeight: "bold", lineHeight: 1.17,
    color: '#fff', textDecoration: 'none',
}

const AddressMail = {
    fontFamily: "Arial",
    fontSize: "12px", fontWeight: "bold", lineHeight: 1.17,
    color: '#fff', textDecoration: 'none'
}


function telWithoutSpaces(number) {
    return number.replace(/\s/g, "");
}

const logoImg = (signature) => (
    <img
        src={`https://files.myclientisrich.com/${signature.imagePath}/${signature.imageName}`}
        width={Image.width}
        height={Image.height}
        alt={compagnyName}
        style={Image}
    />
)

const Signature = ({pageContext}) => {
    const {signature} = pageContext;
    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>
                    {signature.name.firstname} {signature.name.lastname} • {compagnyName}
                </title>
            </Helmet>
            <table className="signature" style={tableStyle}>
                <tbody>
                <tr style={advertBlock}>
                    <td style={advertTextWrapper}>
                        <p>Le cabinet <b>SCHALLER-ROTH-SIMLER</b> change de nom
                            et révèle sa nouvelle identité : Cabinet <b>Un Point Six</b> géomètres experts.</p>
                    </td>
                </tr>
                <tr style={Object.assign({}, {backgroundColor: 'black'})}>
                    <td style={Object.assign({}, {height: '35px'})}>
                    </td>
                </tr>
                <tr style={contentBlock}>
                    <td>
                        <table style={SignatureStyle}>
                            <tbody>
                            <tr>
                                <td className="image__padding" style={ImagePadding}></td>
                                <td className="image__wrapper" style={ImageWrapper}>
                                    {signature.url ? (
                                        <a href={`https://${signature.url}`} target='_blank' rel="noreferrer">
                                            {logoImg(signature)}
                                        </a>
                                    ) : (
                                        logoImg(signature)
                                    )
                                    }
                                </td>
                                <td className="separator" style={SeparatorX}>
                                </td>
                                <td className="text__wrapper" style={TextWrapper}>
                                    <p style={Object.assign({}, Name, {color: "#fff"})}>
                                        {signature.name.firstname} {signature.name.lastname}
                                    </p>

                                    <p style={Job}>{signature.job}</p>

                                    <p style={Phone}>
                                        <a
                                            href={`tel:${telWithoutSpaces(signature.tel.fixe)}`}
                                            style={PhoneLink}
                                        >
                                            Tél. {signature.tel.fixe}
                                        </a>
                                        <br/>
                                        {signature.tel.port ? (
                                            <>
                                                <a
                                                    href={`tel:${telWithoutSpaces(signature.tel.port)}`}
                                                    style={PhoneLink}
                                                >
                                                    Mobile {signature.tel.port}
                                                </a>
                                                <br/>
                                            </>
                                        ) : null}
                                    </p>

                                    <a href={`https://${compagnyUrl}`} target='_blank' rel="noreferrer"
                                       style={Web}>
                                        {compagnyUrl}
                                    </a>

                                    <p style={Object.assign({}, {marginTop: '0', marginBottom: '0'})}>&nbsp;</p>

                                    <div style={Socials}>
                                        {signature.social.fb ? (
                                            <>
                                                <a href={signature.social.fb} target='_blank' rel="noreferrer"
                                                   style={Social}>
                                                    <img
                                                        src={`https://files.myclientisrich.com/${signature.imagePath}/fb.png`}
                                                        alt="Logo Facebook" style={SocialImg}
                                                        width={SocialImg.width}
                                                        height={SocialImg.height}/>
                                                </a>
                                            </>
                                        ) : null}
                                        {signature.social.linkedin ? (
                                            <>
                                                &nbsp; &nbsp;
                                                <a href={signature.social.linkedin} target='_blank' rel="noreferrer"
                                                   style={Social}>
                                                    <img
                                                        src={`https://files.myclientisrich.com/${signature.imagePath}/linkedin.png`}
                                                        alt="Logo LinkedIn" style={SocialImg}
                                                        width={SocialImg.width}
                                                        height={SocialImg.height}/>
                                                </a>
                                            </>
                                        ) : null}
                                        {signature.social.insta ? (
                                            <>
                                                <a href={signature.social.insta} target='_blank' rel="noreferrer"
                                                   style={Social}>
                                                    <img
                                                        src={`https://files.myclientisrich.com/${signature.imagePath}/insta.png`}
                                                        alt="Logo Instagram" style={SocialImg}
                                                        width={SocialImg.width}
                                                        height={SocialImg.height}/>
                                                </a>
                                            </>
                                        ) : null}
                                        {signature.social.web ? (
                                            <>
                                                <a href={signature.social.web} target='_blank' rel="noreferrer"
                                                   style={Social}>
                                                    <img
                                                        src={`https://files.myclientisrich.com/${signature.imagePath}/web.png`}
                                                        alt="Logo Website" style={SocialImg}
                                                        width={SocialImg.width}
                                                        height={SocialImg.height}/>
                                                </a>
                                            </>
                                        ) : null}

                                    </div>

                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
                <tr style={Object.assign({}, {backgroundColor: 'black'})}>
                    <td className="separator" style={SeparatorY}>
                    </td>
                </tr>
                <tr style={AddressesBlock}>
                    <td style={Object.assign({}, {paddingBottom: '20px'})}>
                        <table>
                            <tbody>
                            <tr>
                                <td style={Object.assign({}, {width: '50px'})}>
                                </td>
                                <td style={AddressItem}>
                                    <p style={AddressTitle}>Sélestat</p>
                                    <p style={Address}>PAEI du Giessen<br/>
                                        6 rue de l’Altenberg<br/>
                                        CS 80 008<br/>
                                        67608 SÉLESTAT Cedex
                                    </p>
                                    <p><a href={'tel:0388580000'} style={AddressPhone}>Tél. 03 88 58 00 00</a></p>
                                    <p><a href={'mailto:selestat@unpointsix.fr'}
                                          style={AddressMail}>selestat@unpointsix.fr</a></p>
                                </td>
                                <td style={AddressItem}>
                                    <p style={AddressTitle}>Colmar</p>
                                    <p style={Address}>3 rue Gambetta<br/>
                                        68000 COLMAR
                                        &nbsp;<br/>
                                        &nbsp;<br/>
                                        &nbsp;<br/>
                                    </p>
                                    <p><a href={'tel:0389236743'} style={AddressPhone}>Tél. 03 89 23 67 43</a></p>
                                    <p><a href={'mailto:colmar@unpointsix.fr'}
                                          style={AddressMail}>colmar@unpointsix.fr</a></p>
                                </td>
                                <td style={AddressItem}>
                                    <p style={AddressTitle}>Strasbourg</p>
                                    <p style={Address}>Espace Européen de l’Entreprise<br/>
                                        Immeuble LE BELEM<br/>
                                        5 rue de Lisbonne<br/>
                                        67300 SCHILTIGHEIM<br/>
                                    </p>
                                    <p><a href={'tel:0388328216'} style={AddressPhone}>Tél. 03 88 32 82 16</a></p>
                                    <p><a href={'mailto:strasbourg@unpointsix.fr'}
                                          style={AddressMail}>strasbourg@unpointsix.fr</a></p>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
                </tbody>
            </table>
        </>
    );
};

export default Signature;
